import React, { Fragment } from "react";
import { GatsbyImage, StaticImage } from "gatsby-plugin-image";
import Layout from "../../components/Layout";
import { Link } from "gatsby";
import { graphql } from "gatsby";
import { SEO } from "../../components/SEO";
import Footer from "../../components/Footer";
import { truncateString, removeDateFromString } from "../../utils/common";

const Categories = ({
  data: { allSanityCategory, sanityBlogPage, sanitySite },
}) => {
  //   const { nodes } = allSanityCategory;
  const a = sanityBlogPage.sectionA || [];
  const b = sanityBlogPage.sectionB || [];
  const c = sanityBlogPage.sectionC || [];
  const allList = [...a, ...b, ...c];

  //   const removeDate = (str) => {
  //     const colonIndex = str.indexOf(":");
  //     if (colonIndex !== -1) {
  //       return str.substring(colonIndex + 1);
  //     }
  //     return str;
  //   };

  return (
    <Fragment>
      <Layout headerData={sanitySite?.header}>
        <section className={"bg-black"}>
          <div className={"container mx-auto py-10"}>
            <h1 className={"text-5xl black font-ssp gg-text min-h-[60px]"}>
              {sanityBlogPage?.title}
            </h1>
            <p className={"font-ssp font-bold max-w-lg text-white mt-5"}>
              {sanityBlogPage?.excerpt}
            </p>
          </div>
        </section>
        <div className="container py-10 mx-auto">
          <section
            className={
              "grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-x-7 xl:gap-x-11 gap-y-5 md:gap-y-[35px]"
            }
          >
            {allList.map((category, i) => {
              if (category?.slug) {
                return (
                  <Link
                    to={`/blog/${category.slug.current}`}
                    key={i}
                    className="duration-500 transistion-all hover:bg-slate-100 hover:shadow-xl"
                  >
                    <div>
                      <div className="rounded-md overflow-hidden sm:h-[200px]">
                        {category?.thumbnail ? (
                          <GatsbyImage
                            className="object-cover w-full h-full rounded-md"
                            image={category?.thumbnail?.asset?.gatsbyImageData}
                            alt={category?.title}
                          />
                        ) : (
                          <StaticImage
                            src="../../images/placeholder.webp"
                            alt="placeholder"
                            className="object-cover w-full h-full rounded-md"
                          />
                        )}
                      </div>
                      <div className="px-2 py-2">
                        {category?.title && (
                          <p className={"font-bold font-ssp text-lg"}>
                            {removeDateFromString(category.title)}
                          </p>
                        )}

                        {category?.description && (
                          <p className="pt-1 text-sm font-normal font-ssp lg:text-base">
                            {truncateString(category.description, 200)}
                          </p>
                        )}
                      </div>
                    </div>
                  </Link>
                );
              }
            })}
          </section>
        </div>
        {/* Old 21-03-2024 */}
        {/* <div className={"container mx-auto py-10"}>
          <h2 className={"font-bold text-2xl text-center mb-5"}>
            Online Writing and Strategy
          </h2>
          <section className={"flex gap-10 grid md:grid-cols-2 grid-cols-1"}>
            {sanityBlogPage?.sectionA?.map((category, i) => {
              return (
                <div
                  className={
                    "bg-[#f1f1f16e] rounded-[23px] min-h-[200px] col-span-1"
                  }
                  key={i}
                >
                  <h1
                    className={
                      "border-b border-b-black/[0.44] py-6 px-8"
                    }
                  >
                    {category.title}
                  </h1>
                  <p className={"font-lora py-6 px-8"}>
                    {category.description}
                  </p>
                  <div className={"flex"}>
                    <Link
                      to={`/blog/${category.slug.current}`}
                      className={
                        "uppercase border rounded-lg px-10 py-3 text-sm hover:bg-black hover:text-white transition-all ml-auto my-6 mx-8"
                      }
                    >
                      explore
                    </Link>
                  </div>
                </div>
              );
            })}
          </section>

          <h2 className={"font-bold text-2xl text-center mb-5 mt-10"}>
            Special Topics and Passion Projects
          </h2>
          <section className={"flex gap-10 grid md:grid-cols-2 grid-cols-1"}>
            {sanityBlogPage?.sectionB?.map((category, i) => {
              return (
                <div
                  className={
                    "bg-[#f1f1f16e] rounded-[23px] min-h-[200px] col-span-1"
                  }
                  key={i}
                >
                  <h1
                    className={
                      "border-b border-b-black/[0.44] py-6 px-8"
                    }
                  >
                    {category.title}
                  </h1>
                  <p className={"font-lora py-6 px-8"}>
                    {category.description}
                  </p>
                  <div className={"flex"}>
                    <Link
                      to={`/blog/${category.slug.current}`}
                      className={
                        "uppercase border rounded-lg px-10 py-3 text-sm hover:bg-black hover:text-white transition-all ml-auto my-6 mx-8"
                      }
                    >
                      explore
                    </Link>
                  </div>
                </div>
              );
            })}
          </section>
          <h2 className={"font-bold text-2xl text-center mb-5 mt-10"}>
            The Rest
          </h2>
          <section className={"flex gap-10 grid md:grid-cols-2 grid-cols-1"}>
            {sanityBlogPage?.sectionC?.map((category, i) => {
              return (
                <div
                  className={
                    "bg-[#f1f1f16e] rounded-[23px] min-h-[200px] col-span-1"
                  }
                  key={i}
                >
                  <h1
                    className={
                      "border-b border-b-black/[0.44] py-6 px-8"
                    }
                  >
                    {category.title}
                  </h1>
                  <p className={"font-lora py-6 px-8"}>
                    {category.description}
                  </p>
                  <div className={"flex"}>
                    <Link
                      to={`/blog/${category.slug.current}`}
                      className={
                        "uppercase border rounded-lg px-10 py-3 text-sm hover:bg-black hover:text-white transition-all ml-auto my-6 mx-8"
                      }
                    >
                      explore
                    </Link>
                  </div>
                </div>
              );
            })}
          </section>
        </div> */}

        {/* <section
          className={
            "bg-black mx-10 px-10 py-16 grid grid-cols-6 gap-10 text-white rounded-[37px] flex flex-wrap items-center mb-5"
          }
          data-aos="fade-in"
          data-aos-offset="200"
          data-aos-delay="50"
          data-aos-duration="800"
          data-aos-easing="ease-in"
        >
          <div
            className={"colspan-0 md:col-span-2 text-center hidden md:block"}
          >
            <StaticImage
              src={"../../images/never-miss-a-new-article.svg"}
              alt={"Subscribe"}
            />
          </div>
          <div className={"col-span-6 md:col-span-4 text-center md:text-left"}>
            <h1 className={" uppercase text-2xl"}>
              NEVER MISS A NEW ARTICLE
            </h1>
            <p className={"font-lora mt-2 mb-5"}>
              Subscribe to The Slide Grease and get the latest delivered
              directly to your inbox every Sunday.
            </p>
            <button
              className={
                "bg-gradient-to-r hover:bg-gradient-to-l from-[#F4C943] to-[#FFFF95] text-black py-3 px-10 rounded-lg mt-5 uppercase transition-all"
              }
              data-hs-overlay="#active-campaign-signup-modal"
            >
              Subscribe
            </button>
          </div>
        </section> */}
      </Layout>
      <Footer data={sanitySite?.footer} />
    </Fragment>
  );
};

export default Categories;
export const Head = ({ data: { sanityBlogPage } }) => (
  <SEO metadata={sanityBlogPage?.pageMetadata || {}} />
);
export const query = graphql`
  query {
    allSanityCategory {
      nodes {
        description
        slug {
          current
        }
        title
      }
    }
    sanityBlogPage {
      title
      excerpt
      sectionA {
        title
        description
        slug {
          current
        }
        thumbnail {
          asset {
            gatsbyImageData(placeholder: BLURRED, height: 490)
          }
        }
      }
      sectionB {
        title
        description
        slug {
          current
        }
        thumbnail {
          asset {
            gatsbyImageData(placeholder: BLURRED, height: 490)
          }
        }
      }
      sectionC {
        title
        description
        slug {
          current
        }
        thumbnail {
          asset {
            gatsbyImageData(placeholder: BLURRED, height: 490)
          }
        }
      }
      pageMetadata {
        title
        schema
        keywords
        description
        canonical
        image {
          asset {
            url
          }
        }
      }
    }
    sanitySite {
      header {
        links {
          type
          label
          path
          url
          childMenu {
            type
            label
            path
            url
          }
        }
      }
      footer {
        copyright
        topLinks {
          label
          path
        }
        bottomLinks {
          label
          path
        }
        facebook
        linkedIn
        instagram
        medium
        substack
        youtube
        tiktok
      }
    }
  }
`;
